<template>
  <v-app>
    <v-main class="grey lighten-5">
      <v-row no-gutters class="fill-height">
        <v-col>

          <slide />
          <about-us id="about-us" />
          <product-home id="product-home" />
          <innovation />
          <location id="location" />
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import Slide from "@/components/home/carrousel.vue";
import aboutUs from "@/components/home/about-us.vue";
import ProductHome from "@/components/home/product-home.vue";
import Innovation from "@/components/home/innovation.vue";
import Location from "@/components/home/locations.vue";

export default {
  name: "Home",

  components: {
    Slide,
    aboutUs,
    ProductHome,
    Innovation,
    Location,
  },
};
</script>
