<template>
  <v-container>
    <v-row class="my-16 justify-center">
      <v-col cols="12" lg="5">
        <v-carousel
        cycle
          interval="4000"
          :show-arrows="false"
          hide-delimiter-background
          delimiter-icon="mdi-circle"
          class="carrousel2"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i" :src="publicPath + item.src">
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" lg="5" class="d-flex align-center px-10 px-lg-0">
        <v-row>
          <v-col cols="12" lg="12" class="ml-0">
            <h1 class="copperplate-medium gold h1 my-5 text-center text-lg-left">
              RON DON MIGUEL HOMENAJE
            </h1>
            <p class="josefin-light black-grin p text-lg-justify text-center"
            v-html="$t('productHome.generalDescriptionAboutProduct')">
            </p>
            <v-btn
                  class="btn_second product_home_btn mt-10 mx-auto d-flex mx-lg-0"
                  :ripple="{ class: 'white--text' }"
                  elevation="0"
                  medium
                  to="/productos"
                >
                 {{$t('productHome.btnKnowMore')}}</v-btn
                >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          src: "products/ratf.jpg"
        },
        {
          src: "products/ratm.jpg"
        },
        {
          src: "products/ftf.jpg"
        },
        {
          src: "products/ftm.jpg"
        }
      ]
    };
  }
};
</script>
