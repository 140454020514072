<template>
  <v-container fluid class="odd py-lg-16 ma-0 px-lg-16">
    <v-row class="px-lg-16">
      <v-col cols="12" class="find-us">
        <h1 class="copperplate-medium h1 gold">
          {{ $t("contact.findUsTitle") }}
        </h1>
      </v-col>
      <v-col cols="12" class="find-us">
        <p class="p black-grin josefin-light">
          {{ $t("contact.findUsDescription") }}
        </p>
      </v-col>
    </v-row>
    <v-row class="px-lg-16">
      <v-col cols="12" lg="3" class="ma-0 pa-0">
        <v-col class="d-flex" cols="12">
          <v-select
            name="city"
            id="city"
            solo
            v-model="city"
            :items="getCities"
            item-text="name"
            item-value="name"
            class="select_location"
            single-line
          ></v-select>
        </v-col>
        <v-col class="input_location">
          <v-list class="transparent list-location">
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in getLocations[city]"
                :key="index"
                @click="getIdLocation(item)"
                class="pa-0"
              >
                <v-list-item-content>
                  <v-row class="ma-0 justify-center justify-lg-start pr-lg-10">
                    <v-list-item-title
                      v-text="item.text"
                      class="copperplate-bold h2 text-center text-lg-left"
                    ></v-list-item-title>
                    <p
                      v-text="item.address"
                      class="p josefin-light text-justify"
                    ></p>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-col>
      <v-col cols="12" lg="9" class="pt-6 pr-lg-16">
        <iframe
          width="100%"
          height="100%"
          style="border: 0"
          loading="lazy"
          allowfullscreen
          :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyChODmXijkuuQLsfPCbHgQ8ueXYLqJezRI
            &q=${getLocation.address}`"
        >
        </iframe>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "location",
  created() {
    this.initInfo();
  },
  data() {
    return {
      city: "Santo Domingo",
      local: 1,
      loading: false,
      elevation: 0,
    };
  },
  computed: {
    ...mapGetters("locations", [
      "getLocations",
      "getCity",
      "getCities",
      "getLocation",
    ]),
  },
  methods: {
    ...mapActions("locations", ["initInfo", "getIdLocation"]),
  },
};
</script>
<style lang="scss" scoped>
a {
  color: rgba(0, 0, 0, 0.8) !important;
}
.list-location {
  height: 400px;
  overflow-y: auto;
}
.v-list-item__title {
  white-space: normal;
}
</style>
