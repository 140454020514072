<template>
  <v-container fluid class="pa-0">
    <header-bar
      style="background: linear-gradient(180deg, rgba(0,0,0,0.9836309523809523) 0%, rgba(0,0,0,0.41220238095238093) 65%, rgba(0,0,0,0.053658963585434205) 100%);"
    ></header-bar>
    <v-carousel
      cycle
      interval="2500"
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-circle"
      class="carrousel1"
    >
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="publicPath + item.src">
      </v-carousel-item>
    </v-carousel>
    <v-row cols="12" class="ma-0 py-xl-0 py-10">
      <v-col cols="12" lg="8" class="py-xl-16 pl-xl-16">
        <p class="text-lg-right text-center copperplate-medium text_phrase black-grin">
          {{ $t("menu.phraseDonMiguel") }}
        </p>
        <p class="text-lg-right text-center copperplate-medium text_phrase black-grin">
          Miguel Barceló Pascual
        </p>
      </v-col>
      <v-col cols="12" lg="4">
        <v-img
          src="@/assets/don_miguel.png"
          class="don_miguel_img mt-lg-n16 mb-xl-8 mt-0 mx-auto ml-lg-0"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import bgImg from '@/assets/slider1.jpg';
import headerBar from "@/components/home/header-bar.vue";

export default {
  components: {
    headerBar
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          src: "slider/slider1.jpeg"
        },
        {
          src: "slider/slider2.jpeg"
        },
        {
          src: "slider/slider3.jpeg"
        },
        {
          src: "slider/slider4.jpeg"
        }
      ]
    };
  }
};
</script>
